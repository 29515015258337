import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { serchItem, searchItemClean } from '../actions/ItemAction';
import { Toolbar, AppBar, Typography, Badge, Drawer, Button, IconButton } from "@material-ui/core";
import { fade, makeStyles } from '@material-ui/core/styles';
import { AccountCircle, ShoppingCart, Close, Lock, ChevronRight as ChevronRightIcon } from "@material-ui/icons";
import SearchBar from "material-ui-search-bar";
import { ItemCartScreen } from "../screens";
import { closeToggle } from '../actions/toogleActions'
import VariationScreen from '../screens/VariationsScreen'
import * as Colors from '../colors'
import config from '../config';
import * as cartToggleActions from '../actions/cartToggleActions'
const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            flexGrow: 1,
        }
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        margin: '0 auto',
        width: '50%',
        marginTop: '5px',
        marginBottom: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
    },
    customeSearch: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,

        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            width: '95%',
            margin: '0 auto',
            marginBottom: theme.spacing(1),

        }
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    drawerFooter: {
        position: "fixed",
        bottom: 0,
        width: '40%',
        textAlign: "center",
        paddingLeft: 10,
        paddingBottom: 10,
    },
    customeInput: {
        flexGrow: 1,
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    },
    customeSearchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    loginButton: {
        margin: theme.spacing(1),
    },
    notification: {
        display: 'flex',
        position: 'relative',
        marginLeft: 'auto',
        alignItems: 'center'
    },
    notify: {
        margin: '0 auto',
        position: 'relative',
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    },
    popHeader: {
        display: 'flex',
    },
    popHeaderTitle: {
        color: Colors.colors.primary,
        margin: '0 auto',
        marginTop: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    close: {
        color: 'black',
        width: 30,
        height: 30,
        marginTop: 20,
        marginLeft: 12
    }
}));

export default function Bar(props) {
    let history = useHistory();
    const userSignin = useSelector((state) => state.userSignin);
    const cartToggleState = useSelector((state) => state.cartToggle);
    const toggleState = useSelector((state) => state.toggle);
    const itemList = useSelector((state) => state.itemList);
    const { items = [] } = useSelector((state) => state.cartItem);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [toggle, setToggle] = useState(false)
    const { userInfo } = userSignin;
    const dispatch = useDispatch()
    const classes = useStyles();
    let variationItems = []
    const { open } = toggleState
    if (open) {
        if (itemList.origin) {
            variationItems = itemList.origin.filter(i => i._id === toggleState.itemId)
        }
    }
    const { close } = cartToggleState
    if (close) {
        setToggle(false)
        dispatch(cartToggleActions.resetToggle())
    }
    const onSearchChange = (newValue) => {
        setSearchKeyword(newValue)
    }
    const onRequestSearch = () => {
        dispatch(serchItem(searchKeyword))
        history.replace({ pathname: '/' })
    }
    const onSearchCancel = () => {
        dispatch(searchItemClean())
    }
    const toggleDrawer = () => {
        setToggle(!toggle)
    }
    const bottomToggleDrawer = () => {
        dispatch(closeToggle())
    }
    return (
        <div className={classes.root}>
            <AppBar position="sticky" style={{ backgroundColor: Colors.colors.primary }} elevation={0}>
                <Toolbar>
                    <Link to='/'>
                        <Typography variant="h4" noWrap className={classes.title}>
                            {config.businessName}
                        </Typography>
                    </Link>

                    <div className={classes.search}>
                        <SearchBar
                            value={searchKeyword}
                            onChange={(newValue) => onSearchChange(newValue)}
                            onRequestSearch={() => onRequestSearch()}
                            onCancelSearch={() => onSearchCancel()}
                        />
                    </div>
                    <div className={classes.notification}>
                        <div className={classes.notify}
                            onClick={toggleDrawer}
                        >
                            <Badge color='secondary' badgeContent={items.length || 0}>
                                <ShoppingCart style={{ height: 35, width: 35, color: 'white' }} />
                            </Badge>
                        </div>
                        <div className={classes.notify}>
                            {userInfo ? (
                                <Link to="/profile"><AccountCircle style={{ height: 35, width: 35, color: 'black' }} /></Link>
                            ) : (
                                    <Link to="/signin">
                                        <Button
                                            elevation={0}
                                            variant="contained"
                                            style={{ backgroundColor: 'white' }}
                                            className={classes.loginButton}
                                            startIcon={<Lock style={{ color: '#80A6EA' }} />}
                                        >
                                            <b style={{ color: '#80A6EA', fontSize: 12 }}>Login</b>
                                        </Button>
                                    </Link>
                                )}
                        </div>
                    </div>
                    <Drawer
                        className={classes.drawer}
                        // variant="persistent"
                        anchor='right'
                        open={toggle}
                        onClose={toggleDrawer}
                    >
                        <div className={classes.drawerHeader}>
                            <IconButton onClick={toggleDrawer} >
                                <ChevronRightIcon />
                            </IconButton>
                        </div>
                        <ItemCartScreen {...props} />
                    </Drawer>
                    <Drawer
                        anchor='bottom'
                        open={open}
                        onClose={bottomToggleDrawer}
                    >
                        <div className={classes.popHeader}>
                            <Close className={classes.close}
                                onClick={bottomToggleDrawer}
                                style={{ marginBottom: '20px' }}
                            />
                            <Typography variant="h4" noWrap className={classes.popHeaderTitle}>
                                {variationItems.length > 0 ? variationItems[0].name : ''}
                            </Typography>
                        </div>

                        <VariationScreen items={variationItems} />
                    </Drawer>
                </Toolbar>
                <div className={classes.customeSearch}>
                    <SearchBar
                        value={searchKeyword}
                        onChange={(newValue) => onSearchChange(newValue)}
                        onRequestSearch={() => onRequestSearch()}
                        onCancelSearch={() => onSearchCancel()}
                    />
                </div>
            </AppBar>

        </div>
    );
}